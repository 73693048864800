import { useMemo } from 'react';

import styles from './component.module.scss';

const WhatsAppLinkButton = () => {
  const params = useMemo(() => {
    const params = new URLSearchParams();
    params.set('phone', '573196727697');
    params.set('text', 'Hola, quisiera recibir informacion de');
    params.set('source', 'transactional-femcng');
    return params;
  }, []);

  return (
    <a className={styles.main} href={`https://api.whatsapp.com/send?${params}`}>
      <svg
        width="650"
        height="650"
        viewBox="0 0 650 650"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <g filter="url(#filter0_d_223_5)">
          <circle cx="325" cy="321" r="304" fill="#25D366" />
          <g clip-path="url(#clip0_223_5)">
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M452.546 192.565C418.709 158.685 373.706 140.017 325.756 140C226.951 140 146.538 220.404 146.504 319.235C146.487 350.829 154.746 381.667 170.432 408.844L145 501.736L240.024 476.811C266.203 491.096 295.683 498.616 325.679 498.625H325.756C424.544 498.625 504.966 418.212 505 319.381C505.017 271.483 486.392 226.455 452.546 192.574V192.565ZM325.756 468.354H325.696C298.966 468.346 272.744 461.16 249.865 447.589L244.424 444.358L188.034 459.149L203.083 404.168L199.542 398.53C184.63 374.809 176.749 347.391 176.766 319.244C176.801 237.104 243.633 170.271 325.817 170.271C365.61 170.288 403.015 185.801 431.145 213.966C459.276 242.123 474.755 279.562 474.738 319.364C474.703 401.513 407.871 468.346 325.756 468.346V468.354ZM407.475 356.777C402.997 354.534 380.978 343.704 376.869 342.209C372.761 340.713 369.779 339.966 366.796 344.452C363.814 348.939 355.228 359.029 352.615 362.011C350.002 365.002 347.389 365.372 342.911 363.128C338.434 360.885 324.003 356.158 306.891 340.902C293.578 329.024 284.587 314.362 281.975 309.875C279.362 305.389 281.7 302.965 283.934 300.739C285.945 298.728 288.412 295.505 290.655 292.892C292.899 290.279 293.638 288.406 295.133 285.423C296.629 282.432 295.881 279.82 294.764 277.576C293.646 275.333 284.691 253.287 280.952 244.323C277.316 235.591 273.621 236.777 270.879 236.631C268.266 236.502 265.284 236.476 262.293 236.476C259.302 236.476 254.454 237.594 250.346 242.08C246.238 246.566 234.669 257.404 234.669 279.441C234.669 301.478 250.716 322.785 252.959 325.776C255.202 328.767 284.544 374.001 329.469 393.408C340.153 398.023 348.498 400.782 355.004 402.845C365.731 406.257 375.494 405.776 383.212 404.624C391.816 403.335 409.71 393.786 413.44 383.326C417.17 372.866 417.17 363.893 416.053 362.028C414.936 360.163 411.945 359.037 407.467 356.794L407.475 356.777Z"
              fill="white"
            />
          </g>
        </g>
        <defs>
          <filter
            id="filter0_d_223_5"
            x="0"
            y="0"
            width="650"
            height="650"
            filterUnits="userSpaceOnUse"
            color-interpolation-filters="sRGB"
          >
            <feFlood flood-opacity="0" result="BackgroundImageFix" />
            <feColorMatrix
              in="SourceAlpha"
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
              result="hardAlpha"
            />
            <feOffset dy="4" />
            <feGaussianBlur stdDeviation="10.5" />
            <feComposite in2="hardAlpha" operator="out" />
            <feColorMatrix
              type="matrix"
              values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.42 0"
            />
            <feBlend
              mode="normal"
              in2="BackgroundImageFix"
              result="effect1_dropShadow_223_5"
            />
            <feBlend
              mode="normal"
              in="SourceGraphic"
              in2="effect1_dropShadow_223_5"
              result="shape"
            />
          </filter>
          <clipPath id="clip0_223_5">
            <rect
              width="360"
              height="362"
              fill="white"
              transform="translate(145 140)"
            />
          </clipPath>
        </defs>
      </svg>
    </a>
  );
};

export { WhatsAppLinkButton };
