// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".PrrIJ1tt2rL8CizCn4bL{display:flex;width:100%;height:85px;justify-content:center;align-items:center;padding:10px;border:1px solid #c0314b;background-color:#c0314b}.Bd2eyJ87G6Qn0VPfXFFN{display:flex;width:100%;max-width:1200px;height:100%;justify-content:space-between;align-items:center}.Bd2eyJ87G6Qn0VPfXFFN>div{display:flex;height:100%;align-items:center;justify-content:space-between;flex-flow:row nowrap;column-gap:10px}.Bd2eyJ87G6Qn0VPfXFFN a,.Bd2eyJ87G6Qn0VPfXFFN img{height:100%;width:min-content;background-color:#fff;border-radius:5px}.Bd2eyJ87G6Qn0VPfXFFN .jLzwgC5oUcVR1iN5M6dQ{display:flex;flex-flow:column nowrap;color:#ffe7eb;align-items:flex-start}", "",{"version":3,"sources":["webpack://./src/common/components/Header/component.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,WAAA,CAEA,sBAAA,CACA,kBAAA,CACA,YAAA,CAEA,wBAAA,CACA,wBAAA,CAGF,sBACE,YAAA,CAEA,UAAA,CACA,gBAAA,CACA,WAAA,CAEA,6BAAA,CACA,kBAAA,CAEA,0BACE,YAAA,CACA,WAAA,CAEA,kBAAA,CACA,6BAAA,CACA,oBAAA,CACA,eAAA,CAGF,kDAEE,WAAA,CACA,iBAAA,CACA,qBAAA,CACA,iBAAA,CAGF,4CACE,YAAA,CACA,uBAAA,CACA,aAAA,CAEA,sBAAA","sourcesContent":[".main {\n  display: flex;\n  width: 100%;\n  height: 85px;\n\n  justify-content: center;\n  align-items: center;\n  padding: 10px;\n\n  border: 1px solid #c0314b;\n  background-color: #c0314b;\n}\n\n.content {\n  display: flex;\n\n  width: 100%;\n  max-width: 1200px;\n  height: 100%;\n\n  justify-content: space-between;\n  align-items: center;\n\n  > div {\n    display: flex;\n    height: 100%;\n\n    align-items: center;\n    justify-content:space-between;\n    flex-flow: row nowrap;\n    column-gap: 10px;\n  }\n\n  a,\n  img {\n    height: 100%;\n    width: min-content;\n    background-color: rgb(255, 255, 255);\n    border-radius: 5px;\n  }\n\n  .titles {\n    display: flex;\n    flex-flow: column nowrap;\n    color: #ffe7eb;\n\n    align-items: flex-start;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "PrrIJ1tt2rL8CizCn4bL",
	"content": "Bd2eyJ87G6Qn0VPfXFFN",
	"titles": "jLzwgC5oUcVR1iN5M6dQ"
};
export default ___CSS_LOADER_EXPORT___;
