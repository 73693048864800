import { Button, Checkbox, TextInput } from '@mantine/core';
import { z } from 'zod';
import { useMemo } from 'react';
import { zodResolver } from '@hookform/resolvers/zod';
import { notifications } from '@mantine/notifications';

import { DateInput, NumberInput, Select } from '@/common/components/index.ts';
import { IAPIIndividual } from '@/common/types/api/index.ts';
import { useForm } from '@/common/hooks/index.ts';
import { DocumentType, EducationLevelType, MaritalStatusType } from '@zudi/lib';
import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';

import { FormActions } from '../FormActions/index.ts';
import { FormCard } from '../FormCard/index.ts';

import styles from './component.module.scss';

const BeneficiaryFormSchema = z.object({
  firstName: z.string().min(2, 'El nombre es requerido'),
  lastName: z.string().min(2, 'El apellido es requerido'),
  emailAddress: z.string().email().nullish(),
  gender: z.string().nullish(),
  phone: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  documentId: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  documentType: z.string(),
  documentIssuedAt: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null),
  documentIssuedIn: z.string().nullish(),
  birthDate: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null),
  birthCity: z.string().nullish(),
  maritalStatus: z.string().nullish(),
  educationLevel: z.string().nullish(),
  educationLevelFinished: z.boolean().nullish().default(false),
  residenceAddress: z.string().nullish(),
  residenceNeighbourhood: z.string().nullish(),
  residencePhone: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  residenceType: z.string().nullish(),
  residenceOwnershipType: z.string().nullish(),
  residencePermanencyType: z.string().nullish(),
  residenceStratum: z.number().nullish(),
  householdHead: z.boolean().nullish().default(false)
});

const BeneficiaryForm = ({
  capabilities,
  loadValues
}: {
  capabilities: ('create' | 'save' | 'delete')[];
  loadValues?: () => IAPIIndividual;
}) => {
  const apiProvider = useApiProvider();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();

  const originalData = useMemo(loadValues ?? (() => null), [loadValues]);

  const dataForm = useForm<IAPIIndividual>({
    defaultValues: originalData as any,
    resolver: zodResolver(BeneficiaryFormSchema)
  });

  const onSubmit = dataForm.handleSubmit(async (data) => {
    const loadingNotificationId = Math.random().toString();

    notifications.show({
      id: loadingNotificationId,
      color: 'bleudefrance',
      title: 'Guardando Información',
      message: 'Se esta guardando la información en el servidor',
      loading: true,
      autoClose: false
    });

    try {
      await requestUserDataAuthorization();

      if (originalData) {
        await apiProvider.apiAuthenticated.patch(
          `/individuals/${originalData.id}`,
          data
        );
      } else {
        await apiProvider.apiAuthenticated.post(`/individuals`, data);
      }

      notifications.hide(loadingNotificationId);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    } catch (e) {
      notifications.hide(loadingNotificationId);

      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se guardaba la información',
        autoClose: false
      });
    }
  });

  const onDelete = async () => {
    if (!originalData) {
      throw new Error('Original data has not been loaded');
    }

    const loadingNotificationId = Math.random().toString();

    notifications.show({
      id: loadingNotificationId,
      color: 'bleudefrance',
      title: 'Eliminando beneficiario',
      message: 'Se esta eliminando el beneficiario del servidor',
      loading: true,
      autoClose: false
    });

    try {
      await requestUserDataAuthorization();

      await apiProvider.apiAuthenticated.delete(
        `/individuals/${originalData.id}`
      );

      notifications.hide(loadingNotificationId);

      notifications.show({
        color: 'green',
        title: 'Benificiario eliminado',
        message: 'Se ha eliminado la información correctamente',
        autoClose: 10000
      });
    } catch (e) {
      notifications.hide(loadingNotificationId);

      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message:
          'Se ha presentado un error mientras se eliminaba la información',
        autoClose: false
      });
    }
  };

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.form2 = dataForm;

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard
        title={
          capabilities.includes('create')
            ? `Agregar Beneficiario`
            : `Editar ${originalData?.firstName} ${originalData?.lastName}`
        }
      >
        <TextInput
          label="Nombres"
          {...dataForm.getInputProps('firstName', {})}
        />
        <TextInput
          label="Apellidos"
          {...dataForm.getInputProps('lastName', {})}
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Tipo de Documento"
          data={[
            {
              label: 'Cédula de Ciudadanía',
              value: DocumentType.CEDULA_CIUDADANIA
            },
            {
              label: 'Cédula de Extranjería',
              value: DocumentType.CEDULA_EXTRANJERIA
            },
            {
              label: 'Tarjeta de Identidad',
              value: DocumentType.TARJETA_IDENTIDAD
            },
            {
              label: 'Registro Civil',
              value: DocumentType.REGISTRO_CIVIL
            }
          ]}
          name="documentType"
        />
        <NumberInput<IAPIIndividual>
          control={dataForm.control}
          label="No. Documento"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          name="documentId"
        />
        <DateInput<IAPIIndividual>
          control={dataForm.control}
          label="Fecha de Nacimiento"
          name="birthDate"
        />
        <TextInput
          label="Lugar de Nacimiento"
          {...dataForm.getInputProps('birthCity', {})}
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Estado Civil"
          data={[
            { label: 'Soltero(a)', value: MaritalStatusType.SOLTERO },
            { label: 'Unión Libre', value: MaritalStatusType.UNION_LIBRE },
            { label: 'Casado(a)', value: MaritalStatusType.CASADO }
          ]}
          name="maritalStatus"
        />
        <NumberInput<IAPIIndividual>
          control={dataForm.control}
          label="Teléfono"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          name="phone"
        />
        <Select<IAPIIndividual>
          control={dataForm.control}
          label="Nivel de Estudios"
          data={[
            { label: 'Primaria', value: EducationLevelType.PRIMARIA },
            { label: 'Secundaria', value: EducationLevelType.SECUNDARIA },
            { label: 'Universitario', value: EducationLevelType.UNIVERSITARIA },
            { label: 'Postgrado', value: EducationLevelType.POSTGRADO },
            { label: 'Master', value: EducationLevelType.MASTER },
            { label: 'Doctorado', value: EducationLevelType.DOCTORADO }
          ]}
          name="educationLevel"
        />
        <Checkbox
          label="Nivel de Estudios Completos? (Si/No)"
          {...dataForm.getInputProps('educationLevelFinished', {})}
        />
        {capabilities.includes('save') && (
          <Button type="submit" loading={dataForm.formState.isSubmitting}>
            Guardar
          </Button>
        )}
        {capabilities.includes('delete') && (
          <Button onClick={onDelete}>Eliminar</Button>
        )}
      </FormCard>
      <FormActions>
        {capabilities.includes('create') && (
          <Button type="submit" loading={dataForm.formState.isSubmitting}>
            Agregar
          </Button>
        )}
      </FormActions>
    </form>
  );
};

export { BeneficiaryForm };
