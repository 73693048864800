import React from 'react';
import {
  DefaultMantineColor,
  MantineProvider,
  MantineThemeOverride,
  Tuple
} from '@mantine/core';
import { Notifications } from '@mantine/notifications';
import { DatesProvider } from '@mantine/dates';

type AppColorNames =
  | 'lapislazuli'
  | 'bleudefrance'
  | 'white'
  | 'black'
  | DefaultMantineColor;

declare module '@mantine/core' {
  export interface MantineThemeColorsOverride {
    colors: Record<AppColorNames, Tuple<string, 10>>;
  }
}

const AppTheme: MantineThemeOverride = {
  colors: {
    white: new Array(10).fill('#ffffff') as Tuple<string, 10>,
    black: new Array(10).fill('#000000') as Tuple<string, 10>,
    rojito: [
      '#ffe7eb',
      '#f4c0c9',
      '#e798a7',
      '#dc7084',
      '#d04861',
      '#b72f47',
      '#8f2338',
      '#681827',
      '#400d17',
      '#1c0007'
    ]
  },
  primaryColor: 'rojito',
  fontFamily: '"open sans", sans-serif',
  globalStyles: (theme) => ({
    body: {
      backgroundColor: theme.colors.rojito[0]
    }
  })
};

const MantineLayoutProvider = ({
  children
}: {
  children?: React.ReactNode;
}) => {
  return (
    <MantineProvider withGlobalStyles withNormalizeCSS theme={AppTheme}>
      <DatesProvider
        settings={{ firstDayOfWeek: 0, weekendDays: [0, 6], locale: 'es' }}
      >
        <Notifications />
        {children}
      </DatesProvider>
    </MantineProvider>
  );
};

export { MantineLayoutProvider };
