import { Grid, Paper, Space, Title } from '@mantine/core';
import { Children } from 'react';

const FormCard = ({
  title,
  children
}: {
  title?: string;
  children?: React.ReactNode;
}) => {
  return (
    <Paper shadow="xs" p="xs">
      <Title order={4} weight={400}>
        {title}
      </Title>
      <Space h={5} />
      <Grid columns={4} gutter={'xs'} align="flex-end">
        {Children.toArray(children).map((child, idx) => (
          <Grid.Col key={idx} span={1}>
            {child}
          </Grid.Col>
        ))}
      </Grid>
    </Paper>
  );
};

export { FormCard };
