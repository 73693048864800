import { useEffect } from 'react';
import { Button, Card, Flex, Group, Image, Text } from '@mantine/core';
import { Link } from 'react-router-dom';

import { Authenticated } from '@/common/components/index.ts';
import { useHeaderProvider } from '@/common/providers/index.ts';

import styles from './view.module.scss';

type IDashboardLink = {
  title: string;
  url: string;
};

const DashboardLinks: IDashboardLink[] = [
  { title: 'Reglamentación & Normatividad', url: '/legal/docs' },
  { title: 'Actualización de Datos', url: '/associate/information/update' }
];

const DashboardView = () => {
  const { setTitle } = useHeaderProvider();

  useEffect(() => {
    setTitle('Dashboard');
  }, []);

  return (
    <Authenticated>
      <div className={styles.main}>
        <Flex gap={20} wrap={'wrap'}>
          {DashboardLinks.map((dashboardLink) => (
            <Link to={dashboardLink.url}>
              <Card
                w={300}
                h={300}
                shadow="sm"
                padding="lg"
                radius="md"
                withBorder
                key={dashboardLink.url}
                sx={{
                  display: 'flex',
                  flexDirection: 'column',
                  justifyContent: 'space-between'
                }}
              >
                <Card.Section>
                  <Image
                    src={
                      'https://images.unsplash.com/photo-1527004013197-933c4bb611b3?ixid=MnwxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8&ixlib=rb-1.2.1&auto=format&fit=crop&w=720&q=80'
                    }
                    height={160}
                  />
                </Card.Section>

                <Group position="apart" mt="md" mb="xs">
                  <Text weight={500}>{dashboardLink.title}</Text>
                </Group>

                <Button
                  variant="light"
                  color="bleudefrance"
                  fullWidth
                  mt="md"
                  radius="md"
                >
                  Acceder
                </Button>
              </Card>
            </Link>
          ))}
        </Flex>
      </div>
    </Authenticated>
  );
};

export { DashboardView };
