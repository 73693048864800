// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".c02EMI2dC1eDYYcP7NMr{display:flex;width:100%;height:max-content;justify-content:center;align-items:center}.Cx7mLb_7VNAegC6dLmaL{width:320px}.PriaVrwgYIyJXXhHaOYs{display:flex;color:#400d17;justify-content:center;align-items:center;flex-flow:column nowrap;row-gap:10px}.tpbBmWzBP9h8sFOEizUD{display:flex;align-items:center;column-gap:10px}.PlPfcyIGh51NbjtSttjT{color:red;font-size:14px;text-align:center}", "",{"version":3,"sources":["webpack://./src/features/auth/views/SignInView/view.module.scss"],"names":[],"mappings":"AAAA,sBACE,YAAA,CACA,UAAA,CACA,kBAAA,CAEA,sBAAA,CACA,kBAAA,CAGF,sBACE,WAAA,CAGF,sBACE,YAAA,CACA,aAAA,CACA,sBAAA,CACA,kBAAA,CACA,uBAAA,CACA,YAAA,CAGF,sBACE,YAAA,CAEA,kBAAA,CACA,eAAA,CAGF,sBACE,SAAA,CACA,cAAA,CACA,iBAAA","sourcesContent":[".main {\n  display: flex;\n  width: 100%;\n  height: max-content;\n\n  justify-content: center;\n  align-items: center;\n}\n\n.signInCard {\n  width: 320px;\n}\n\n.signInForm {\n  display: flex;\n  color: #400d17;\n  justify-content: center;\n  align-items: center;\n  flex-flow: column nowrap;\n  row-gap: 10px;\n}\n\n.title {\n  display: flex;\n\n  align-items: center;\n  column-gap: 10px;\n}\n\n.error{\n  color: red;\n  font-size: 14px;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"main": "c02EMI2dC1eDYYcP7NMr",
	"signInCard": "Cx7mLb_7VNAegC6dLmaL",
	"signInForm": "PriaVrwgYIyJXXhHaOYs",
	"title": "tpbBmWzBP9h8sFOEizUD",
	"error": "PlPfcyIGh51NbjtSttjT"
};
export default ___CSS_LOADER_EXPORT___;
