import { useEffect, useMemo } from 'react';
import {
  Box,
  Button,
  Checkbox,
  Flex,
  Paper,
  Text,
  TextInput,
  Tooltip
} from '@mantine/core';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import lodash from 'lodash';
import { Decimal } from 'decimal.js';
import { IconInfoCircle } from '@tabler/icons-react';
import { notifications } from '@mantine/notifications';

import { IAPIEmployerBranch, IAPIUser } from '@/common/types/api/index.ts';
import {
  DocumentType,
  EducationLevelType,
  MaritalStatusType,
  ResidenceOwnershipType,
  ResidencePermanencyType,
  ResidenceType
} from '@zudi/lib';
import { useFetchableResource, useForm } from '@/common/hooks/index.ts';
import { Select, NumberInput, DateInput } from '@/common/components/index.ts';
import {
  useApiProvider,
  useDataAuthorizationProvider
} from '@/common/providers/index.ts';

import { FormActions } from '../FormActions/index.ts';
import { FormCard } from '../FormCard/index.ts';

import styles from './component.module.scss';

/**
 * Source:
 * https://www.gub.uy/secretaria-nacional-lucha-contra-lavado-activos-financiamiento-terrorismo/que-es-una-persona-expuesta-politicamentepep
 */
const PEPDefinitionTooltipContent =
  'Son individuos que cumplen o a quienes se les han confiado funciones públicas prominentes, como por ejemplo los Jefes de Estado o de Gobierno, políticos de alto nivel, funcionarios gubernamentales o judiciales de alto nivel o militares de alto rango, ejecutivos de alto nivel de corporaciones estatales, funcionarios de partidos políticos importantes.';

const AssociateFormSchema = z.object({
  emailAddress: z.string().email().nullish(),
  workProfession: z.string().nullish(),
  workRole: z.string().nullish(),
  workStartsAt: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null),
  workEndsAt: z.coerce
    .date()
    .nullish()
    .transform((date) => date?.toISOString() ?? null),
  financePEPIndividual: z.boolean().default(false),
  financePEPRelatives: z.boolean().default(false),
  financeForeignTransactionsIndividual: z.boolean().default(false),
  financeActives: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  financePasives: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  financeEquity: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  financeMonthlyIncome: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  financeMonthlyExpenses: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  financeMonthlyIncomeExtras: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  financeMonthlyIncomeExtrasDesc: z.string().nullish(),
  financeMonthlyDebitedAmountForFund: z.coerce
    .number()
    .nullish()
    .transform((number) => (number === null ? null : String(number))),
  workEmployerId: z.string().uuid().nullish(),
  individual: z.object({
    firstName: z.string().min(2, 'El nombre es requerido'),
    lastName: z.string().min(2, 'El apellido es requerido'),
    emailAddress: z.string().email().nullish(),
    gender: z.string().nullish(),
    phone: z.coerce
      .number()
      .nullish()
      .transform((number) => (number === null ? null : String(number))),
    documentId: z.coerce
      .number()
      .nullish()
      .transform((number) => (number === null ? null : String(number))),
    documentType: z.string(),
    documentIssuedAt: z.coerce
      .date()
      .nullish()
      .transform((date) => date?.toISOString() ?? null),
    documentIssuedIn: z.string().nullish(),
    birthDate: z.coerce
      .date()
      .nullish()
      .transform((date) => date?.toISOString() ?? null),
    birthCity: z.string().nullish(),
    maritalStatus: z.string().nullish(),
    educationLevel: z.string().nullish(),
    educationLevelFinished: z.boolean().nullish().default(false),
    residenceAddress: z.string().nullish(),
    residenceNeighbourhood: z.string().nullish(),
    residencePhone: z.coerce
      .number()
      .nullish()
      .transform((number) => (number === null ? null : String(number))),
    residenceType: z.string().nullish(),
    residenceOwnershipType: z.string().nullish(),
    residencePermanencyType: z.string().nullish(),
    residenceStratum: z.number().nullish(),
    householdHead: z.boolean().nullish().default(false)
  })
});

const DecimalOrNull = (value: any) => {
  try {
    return new Decimal(value);
  } catch (e) {
    return null;
  }
};

const AssociateForm = ({
  capabilities,
  loadValues
}: {
  capabilities: ('create' | 'save' | 'delete')[];
  loadValues?: () => IAPIUser;
}) => {
  const apiProvider = useApiProvider();
  const { requestUserDataAuthorization } = useDataAuthorizationProvider();
  const employerBranches = useFetchableResource<IAPIEmployerBranch[]>([]);

  const originalData = useMemo(loadValues ?? (() => null), [loadValues]);

  const dataForm = useForm<IAPIUser>({
    resolver: zodResolver(AssociateFormSchema)
  });

  const formValues = dataForm.watch();

  const fetchEmployerBranches = employerBranches.wrappedFetch(async () => {
    const response = await apiProvider.apiAuthenticated.get<{
      data: IAPIEmployerBranch[];
    }>('/employers/branches');

    return response.data.data;
  });

  const onSubmit = dataForm.onSubmit(async (data) => {
    if (!originalData) {
      throw new Error('No original data available');
    }

    const loadingNotificationId = Math.random().toString();

    notifications.show({
      id: loadingNotificationId,
      color: 'bleudefrance',
      title: 'Guardando Información',
      message: 'Se esta guardando la información en el servidor',
      loading: true,
      autoClose: false
    });

    try {
      await requestUserDataAuthorization();

      await apiProvider.apiAuthenticated.patch(
        `/users/${originalData.id}`,
        data
      );

      await apiProvider.apiAuthenticated.patch(
        `/individuals/${originalData.individual?.id}`,
        data.individual
      );

      notifications.hide(loadingNotificationId);

      notifications.show({
        color: 'green',
        title: 'Información Guardada',
        message: 'Se ha guardado la información correctamente',
        autoClose: 10000
      });
    } catch (e) {
      notifications.hide(loadingNotificationId);

      notifications.show({
        color: 'red',
        title: 'Ha ocurrido un error',
        message: `Se ha presentado un error mientras se guardaba la información: ${String(
          e
        )}`,
        autoClose: false
      });
    }
  });

  const onDelete = async () => {
    return;
  };

  useEffect(() => {
    if (originalData) {
      for (const name of dataForm.control._names.mount) {
        dataForm.setValue(name as any, lodash.get(originalData, name));
      }
    }
  }, [originalData]);

  useEffect(() => {
    fetchEmployerBranches();
  }, []);

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-ignore
  window.form = dataForm;

  return (
    <form onSubmit={onSubmit} className={styles.main}>
      <FormCard title="Información Básica">
        <TextInput
          label="Nombres"
          readOnly
          {...dataForm.getInputProps('individual.firstName', {})}
        />
        <TextInput
          label="Apellidos"
          readOnly
          {...dataForm.getInputProps('individual.lastName', {})}
        />
        <TextInput
          label="Correo Electronico"
          readOnly
          {...dataForm.getInputProps('emailAddress', {})}
        />
        <Select<IAPIUser>
          control={dataForm.control}
          label="Tipo de Documento"
          data={[
            {
              label: 'Cédula de Ciudadanía',
              value: DocumentType.CEDULA_CIUDADANIA
            },
            {
              label: 'Cédula de Extranjería',
              value: DocumentType.CEDULA_EXTRANJERIA
            },
            {
              label: 'Tarjeta de Identidad',
              value: DocumentType.TARJETA_IDENTIDAD
            },
            {
              label: 'Registro Civil',
              value: DocumentType.REGISTRO_CIVIL
            }
          ]}
          readOnly
          name="individual.documentType"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="No. Documento"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          readOnly
          name="individual.documentId"
        />
        <DateInput<IAPIUser>
          control={dataForm.control}
          label="Fecha Expedición Documento"
          name="individual.documentIssuedAt"
        />
        <TextInput
          label="Lugar Expedición Documento"
          {...dataForm.getInputProps('individual.documentIssuedIn', {})}
        />
        <DateInput<IAPIUser>
          control={dataForm.control}
          label="Fecha de Nacimiento"
          name="individual.birthDate"
        />
        <TextInput
          label="Lugar de Nacimiento"
          {...dataForm.getInputProps('individual.birthCity', {})}
        />
        <Select<IAPIUser>
          control={dataForm.control}
          label="Estado Civil"
          data={[
            { label: 'Soltero(a)', value: MaritalStatusType.SOLTERO },
            { label: 'Unión Libre', value: MaritalStatusType.UNION_LIBRE },
            { label: 'Casado(a)', value: MaritalStatusType.CASADO }
          ]}
          name="individual.maritalStatus"
        />
        <TextInput
          label="Correo Electronico Alterno"
          {...dataForm.getInputProps('individual.emailAddress', {})}
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Teléfono"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          name="individual.phone"
        />
      </FormCard>
      <FormCard title="Información Laboral y Económica">
        <TextInput
          label="Profesión u Oficio"
          {...dataForm.getInputProps('workProfession', {})}
        />
        <TextInput
          label="Cargo Actual"
          {...dataForm.getInputProps('workRole', {})}
        />
        <DateInput<IAPIUser>
          control={dataForm.control}
          label="Inicio Labores"
          name="workStartsAt"
        />

        <Select<IAPIUser>
          control={dataForm.control}
          label="Empresa"
          data={employerBranches.resource.map((branch) => ({
            label: `${branch.name} - ${branch.employer.name}`,
            value: branch.id
          }))}
          readOnly
          name="workEmployerBranchId"
        />
        <Select<IAPIUser>
          control={dataForm.control}
          label="Nivel de Estudios"
          data={[
            { label: 'Primaria', value: EducationLevelType.PRIMARIA },
            { label: 'Secundaria', value: EducationLevelType.SECUNDARIA },
            { label: 'Técnico', value: EducationLevelType.TECNICO },
            { label: 'Universitario', value: EducationLevelType.UNIVERSITARIA },
            { label: 'Postgrado', value: EducationLevelType.POSTGRADO },
            { label: 'Master', value: EducationLevelType.MASTER },
            { label: 'Doctorado', value: EducationLevelType.DOCTORADO }
          ]}
          name="individual.educationLevel"
        />
        <Flex>
          <Checkbox
            label="Nivel de Estudios Completos? (Si/No)"
            {...dataForm.getInputProps('individual.educationLevelFinished', {})}
          />
        </Flex>
      </FormCard>
      <FormCard title="Información Personal y Familiar">
        <Select<IAPIUser>
          control={dataForm.control}
          label="Tipo Residencia"
          data={[
            { label: 'Casa', value: ResidenceType.CASA },
            { label: 'Apartamento', value: ResidenceType.APARTAMENTO },
            { label: 'Lote', value: ResidenceType.LOTE }
          ]}
          name="individual.residenceType"
        />
        <TextInput
          label="Dirección Residencia"
          {...dataForm.getInputProps('individual.residenceAddress', {})}
        />
        <TextInput
          label="Barrio Residencia"
          {...dataForm.getInputProps('individual.residenceNeighbourhood', {})}
        />
        {/**
         * @todo
         * Phone pattern
         */}
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Teléfono Residencia"
          hideControls
          parser={(value) => value.replace(/[^\d]*/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseInt(value))
              ? `${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, '.')
              : ''
          }
          name="individual.residencePhone"
        />
        <Select<IAPIUser>
          control={dataForm.control}
          label="Propiedad Residencia"
          data={[
            {
              label: 'Propia en Hipoteca',
              value: ResidenceOwnershipType.PROPIA_HIPOTECA
            },
            {
              label: 'Propia sin Hipoteca',
              value: ResidenceOwnershipType.PROPIA_NO_HIPOTECA
            },
            {
              label: 'Familiar',
              value: ResidenceOwnershipType.FAMILIAR
            },
            {
              label: 'En Arriendo',
              value: ResidenceOwnershipType.ARRIENDO
            }
          ]}
          name="individual.residenceOwnershipType"
        />
        <Select<IAPIUser>
          control={dataForm.control}
          label="Permanencia Residencia"
          data={[
            {
              label: 'Menos de un año',
              value: ResidencePermanencyType.ONE_YEAR
            },
            {
              label: 'Entre 1 y 5 años',
              value: ResidencePermanencyType.ONE_TO_FIVE_YEARS
            },
            {
              label: 'Más de 5 años',
              value: ResidencePermanencyType.FIVE_YEARS_PLUS
            }
          ]}
          name="individual.residencePermanencyType"
        />
        <Select<IAPIUser>
          control={dataForm.control}
          label="Estrato"
          data={[
            ...(function* () {
              for (let i = 0; i < 6; i++) {
                yield {
                  label: String(i + 1),
                  value: (i + 1) as unknown as string
                };
              }
            })()
          ]}
          name="individual.residenceStratum"
        />
        <Checkbox
          label="Es usted madre/padre cabeza de hogar?"
          {...dataForm.getInputProps('individual.householdHead', {})}
        />
      </FormCard>
      <FormCard title="Información Financiera">
        <Checkbox
          label={[
            'Es Individuo PEP',
            <Tooltip
              label={PEPDefinitionTooltipContent}
              width={300}
              multiline
              transitionProps={{ transition: 'fade', duration: 300 }}
            >
              <Box sx={{ display: 'inline-flex', paddingLeft: '5px' }}>
                <IconInfoCircle size={'17px'} />
              </Box>
            </Tooltip>
          ]}
          {...dataForm.getInputProps('financePEPIndividual', {})}
        />
        <Checkbox
          label={[
            'Tiene Lazos Familiares Con Un Individuo PEP',
            <Tooltip
              label={PEPDefinitionTooltipContent}
              width={300}
              multiline
              transitionProps={{ transition: 'fade', duration: 300 }}
            >
              <Box sx={{ display: 'inline-flex', paddingLeft: '5px' }}>
                <IconInfoCircle size={'17px'} />
              </Box>
            </Tooltip>
          ]}
          {...dataForm.getInputProps('financePEPRelatives', {})}
        />
        <Checkbox
          label="Realiza Movimientos Financieros Internacionales"
          {...dataForm.getInputProps(
            'financeForeignTransactionsIndividual',
            {}
          )}
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Activos Totales"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="financeActives"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Pasivos Totales"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="financePasives"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Patrimonio Total"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="financeEquity"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Ingresos Mensuales"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          withAsterisk
          name="financeMonthlyIncome"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Egresos Mensuales"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="financeMonthlyExpenses"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Ingresos Mensuales Adicionales"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          name="financeMonthlyIncomeExtras"
        />
        <TextInput
          label="Descripción Ingresos Mensuales Adicionales"
          name="financeMonthlyIncomeExtrasDesc"
        />
        <NumberInput<IAPIUser>
          control={dataForm.control}
          label="Cuota Mensual FemCNG"
          hideControls
          parser={(value) => value.replace(/\$\s?|(,*)/g, '')}
          formatter={(value) =>
            !Number.isNaN(parseFloat(value))
              ? `$ ${value}`.replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ',')
              : '$ '
          }
          withAsterisk
          name="financeMonthlyDebitedAmountForFund"
        />
      </FormCard>
      <Paper shadow="xs" p="xs">
        <Text size={'sm'}>
          * De acuerdo con el Estatuto de femCNG, la cuota mensual debe estar
          entre el 4% y el 10% de su ingreso mensual (
          {DecimalOrNull(formValues.financeMonthlyIncome ?? 0)
            ?.times(0.04)
            .toFixed()}{' '}
          -{' '}
          {DecimalOrNull(formValues.financeMonthlyIncome ?? 0)
            ?.times(0.1)
            .toFixed()}
          ).
        </Text>
      </Paper>
      <FormActions>
        {capabilities.includes('create') && (
          <Button type="submit">Agregar</Button>
        )}
        {capabilities.includes('save') && (
          <Button type="submit" loading={dataForm.formState.isSubmitting}>
            Guardar
          </Button>
        )}
        {capabilities.includes('delete') && (
          <Button onClick={onDelete}>Eliminar</Button>
        )}
      </FormActions>
    </form>
  );
};

export { AssociateForm };
