import { useEffect } from 'react';
import { Flex, Loader, Tabs } from '@mantine/core';

import { Authenticated, Typography } from '@/common/components/index.ts';
import {
  useApiProvider,
  useAuthProvider,
  useHeaderProvider
} from '@/common/providers/index.ts';
import { useFetchableResource } from '@/common/hooks/index.ts';
import { FetchStatus } from '@/common/consts/fetch_status.ts';
import { IAPIUser } from '@/common/types/api/user.type.ts';

import { AssociateForm, BeneficiaryForm } from './components/index.tsx';
import styles from './view.module.scss';

const InformationUpdateView = () => {
  const apiProvider = useApiProvider();
  const authProvider = useAuthProvider();
  const { setTitle } = useHeaderProvider();

  const user = useFetchableResource<IAPIUser | null>(null);

  const fetchUserData = user.wrappedFetch(async () => {
    if (!authProvider.cognitoUser) {
      return;
    }

    const response = await apiProvider.apiAuthenticated.get(
      `/users/${authProvider.cognitoUser.claims.sub}`
    );

    return response.data.data;
  });

  useEffect(() => {
    fetchUserData();
    setTitle('Actualización Información Asociado');
  }, []);

  return (
    <Authenticated>
      <div className={styles.main}>
        {user.status === FetchStatus.PENDING && (
          <Loader size="lg" variant="bars" />
        )}
        {user.resource !== null && (
          <Tabs variant="pills" orientation="vertical" defaultValue="associate">
            <Tabs.List pr="sm">
              <Tabs.Tab value="associate">Asociado</Tabs.Tab>
              <Tabs.Tab value="beneficiaries">Beneficiarios</Tabs.Tab>
              {/* <Tabs.Tab value="internationalTransfers">Transferencias</Tabs.Tab>
            <Tabs.Tab value="registeredPets">Mascotas</Tabs.Tab>
            <Tabs.Tab value="registeredVehicles">Vehiculos</Tabs.Tab> */}
            </Tabs.List>

            <Tabs.Panel value="associate">
              <AssociateForm
                capabilities={['save']}
                loadValues={() => user.resource as IAPIUser}
              />
            </Tabs.Panel>

            <Tabs.Panel value="beneficiaries">
              <Flex direction="column" gap="10px">
                {user.resource &&
                  user.resource.beneficiaries.map((beneficiary) => (
                    <BeneficiaryForm
                      loadValues={() => beneficiary}
                      capabilities={['delete']}
                    />
                  ))}
                <BeneficiaryForm capabilities={['create']} />
              </Flex>
            </Tabs.Panel>

            <Tabs.Panel value="internationalTransfers">
              <Typography.Title variants={['large']}>
                Información Financiera Internacional
              </Typography.Title>
            </Tabs.Panel>

            <Tabs.Panel value="registeredPets">
              <Typography.Title variants={['large']}>
                Mascotas Registradas
              </Typography.Title>
            </Tabs.Panel>

            <Tabs.Panel value="registeredVehicles">
              <Typography.Title variants={['large']}>
                Vehiculos Registrados
              </Typography.Title>
            </Tabs.Panel>
          </Tabs>
        )}
      </div>
    </Authenticated>
  );
};

export { InformationUpdateView };
